import React from "react"
import {Link} from 'gatsby'
import GlobalCSS from '../components/GlobalCSS'
import '../scss/404.scss'
import Layout from '../components/layout'

import { storeEvent } from 'event-service/modules/capture'

const renderRedirect = () => {
  if (typeof window !== 'undefined') {
    storeEvent({
        page_type: 'Page Not Found',
        event: 'asc_pageview',
        error_code: '404',
        item_id: '',
        item_number: '',
        item_price: '',
        item_condition: '',
        item_year: '',
        item_make: '',
        item_model: '',
        item_variant: '',
        item_color: '',
        item_type: '',
        item_category: '',
        item_fuel_type: '',
        item_inventory_date: '',
        item_results: '',
    }, {
        handlers: ['ASC']
    })
    
    window.location = window.location.origin
  } else {
      console.log("Redirect Failed")
  }
}

const render404 = () => {
  return (
    <Layout PageNotFound={true} seo={{seo:{MetaTitle:"Page Not Found", MetaDesc:"The page you are looking for was not found.",tags:[]}}}>
      <div style={{height:"calc(100vh - 158px)", background: "var(--primary-color)", padding: "3em"}}>
            <h1 className="notFoundTitle" style={{color: "var(--primary-accent)", fontSize: "5em", fontFamily: '"Overpass", sans-serif', fontWeight: "200", textTransform: "uppercase"}}>Page Not Found</h1>
            <p className="notFoundText" style={{color: "var(--primary-text)", fontSize: "2em", fontFamily: '"Overpass", sans-serif', fontWeight: "700", textTransform: "uppercase"}}>The page you are looking for couldn&apos;t be found. Visit the <Link to={"/"} >homepage</Link>. </p>
      </div>
    </Layout>
  )
}

function notFound({data, location}) {
  return (
    <>
      {renderRedirect()}
    </>
  )
}

export default notFound


